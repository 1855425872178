
import { Landscape } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import FlowDeleteDialog from '../components/delete-dialog.vue'
import FlowsTable from '../components/table.vue'
import * as analytics from '../helpers/analytics'

@Component({
  components: {
    FlowDeleteDialog,
    FlowsTable
  },
  name: 'FlowsOverview'
})
export default class FlowsOverview extends Vue {
  shareModule = getModule(ShareModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get currentLandscapeId () {
    return this.currentVersion?.landscapeId || this.$params.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))
  }

  @Watch('currentLandscape')
  onCurrentLandscapeChanged (currentLandscape?: Landscape, prevCurrentLandscape?: Landscape) {
    if (currentLandscape && currentLandscape?.id !== prevCurrentLandscape?.id) {
      analytics.flowsScreen.track(this, {
        landscapeId: [currentLandscape.id],
        organizationId: [currentLandscape.organizationId]
      })
    }
  }

  mounted () {
    if (this.currentLandscape) {
      analytics.flowsScreen.track(this, {
        landscapeId: [this.currentLandscape.id],
        organizationId: [this.currentLandscape.organizationId]
      })
    }
  }
}
