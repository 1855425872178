
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'

import { FlowModule } from '@/modules/flow/store'

@Component({
  name: 'FlowThumbnailMenu'
})
export default class extends Vue {
  flowModule = getModule(FlowModule, this.$store)

  @Prop({ default: null }) readonly flowId!: string | null
  @Prop() readonly menuProps?: any
  @Prop({ default: false, type: Boolean }) readonly openOnLoad!: boolean
  @Prop() readonly openDelay?: number

  visible = false
  visibleTimer?: number

  get flow () {
    return this.flowId ? this.flowModule.flows[this.flowId] : undefined
  }

  get flowThumbnailUrl () {
    return this.flowId ? this.flowModule.flowThumbnails[this.flowId]?.url : undefined
  }

  @Watch('flowId')
  onFlowIdChanged () {
    this.resetVisibleTimer()
  }

  destroy () {
    clearTimeout(this.visibleTimer)
  }

  loadThumbnail () {
    if (this.flow && !this.flowThumbnailUrl) {
      try {
        this.flowModule.flowThumbnailGet({
          flowId: this.flow.id,
          landscapeId: this.flow?.landscapeId,
          versionId: this.flow?.versionId
        })
      } catch (err) {}
    }
  }

  resetVisibleTimer () {
    if (this.openDelay) {
      this.visible = false
      clearTimeout(this.visibleTimer)
      this.visibleTimer = window.setTimeout(() => {
        this.visible = true
      }, this.openDelay)
    } else {
      this.visible = true
    }
  }
}
