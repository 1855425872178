
import { Task } from '@icepanel/platform-api-client'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

import Dialog from '@/components/dialog.vue'
import { AlertModule } from '@/modules/alert/store'
import { DiagramModule } from '@/modules/diagram/store'
import { EditorModule } from '@/modules/editor/store'
import { FlowModule } from '@/modules/flow/store'
import { LandscapeModule } from '@/modules/landscape/store'
import { ShareModule } from '@/modules/share/store'
import { VersionModule } from '@/modules/version/store'

import * as analytics from '../helpers/analytics'

@Component({
  components: {
    Dialog
  },
  name: 'FlowDeleteDialog'
})
export default class extends Vue {
  alertModule = getModule(AlertModule, this.$store)
  editorModule = getModule(EditorModule, this.$store)
  flowModule = getModule(FlowModule, this.$store)
  diagramModule = getModule(DiagramModule, this.$store)
  landscapeModule = getModule(LandscapeModule, this.$store)
  shareModule = getModule(ShareModule, this.$store)
  versionModule = getModule(VersionModule, this.$store)

  get flowHandleId () {
    return this.$queryValue('flow_delete_dialog')
  }

  get currentDiagramHandleId () {
    return this.$queryValue('diagram')
  }

  get currentFlowHandleId () {
    return this.$queryValue('flow')
  }

  get currentLandscapeId () {
    return this.$params.landscapeId || this.currentVersion.landscapeId
  }

  get currentVersionId () {
    return this.$params.versionId || this.currentShareLink?.versionId || 'latest'
  }

  get currentShareLink () {
    return this.shareModule.shareLinks.find(o => o.shortId === this.$params.shortId)
  }

  get currentVersion () {
    return this.versionModule.versions.find(o => o.id === this.currentVersionId || o.tags.includes(this.currentVersionId))!
  }

  get currentLandscape () {
    return this.landscapeModule.landscapes.find(o => o.id === this.currentLandscapeId)!
  }

  get currentDiagram () {
    return Object.values(this.diagramModule.diagrams).find(o => o.handleId === this.currentDiagramHandleId)
  }

  get flow () {
    return Object.values(this.flowModule.flows).find(o => o.handleId === this.flowHandleId)
  }

  get currentFlow () {
    return Object.values(this.flowModule.flows).find(o => o.handleId === this.currentFlowHandleId)
  }

  get flowStepCount () {
    return Object.values(this.flow?.steps || {}).filter(o => o.type && !o.type.endsWith('-path')).length
  }

  get flowPathCount () {
    return Object.values(this.flow?.steps || {}).filter(o => o.type && o.type.endsWith('-path')).length
  }

  opened () {
    analytics.flowDeleteDialog.track(this, {
      landscapeId: [this.currentLandscape.id],
      organizationId: [this.currentLandscape.organizationId]
    })
  }

  async deleteFlow () {
    const flowId = this.flow?.id
    const currentFlowId = this.currentFlow?.id
    if (flowId && this.flow) {
      const revertTasks: Task[] = [{
        id: flowId,
        props: {
          diagramId: this.flow.diagramId,
          handleId: this.flow.handleId,
          name: this.flow.name,
          showConnectionNames: this.flow.showConnectionNames,
          steps: this.flow.steps
        },
        type: 'flow-create'
      }, {
        route: {
          ...this.$route,
          query: {
            ...this.$route.query,
            flow_delete_dialog: null
          }
        },
        type: 'navigation'
      }]

      this.flowModule.removeFlow(flowId)
      this.editorModule.addToTaskQueue({
        func: () => this.flowModule.flowDelete({
          flowId,
          landscapeId: this.currentLandscape.id,
          versionId: this.currentVersion.id
        })
      })

      this.$emit('delete', flowId)

      if (flowId === currentFlowId) {
        await this.$replaceQuery({
          flow: undefined,
          flow_delete_dialog: undefined,
          flow_parent: undefined,
          flow_path: undefined,
          flow_step: undefined
        })
      } else {
        await this.$replaceQuery({
          flow_delete_dialog: undefined
        })
      }

      this.editorModule.addTaskList({
        revertTasks,
        tasks: [{
          id: flowId,
          type: 'flow-delete'
        }, {
          route: this.$route,
          type: 'navigation'
        }]
      })

      this.alertModule.pushAlert({
        message: 'Flow deleted'
      })
    }
  }
}
